/**
 * @copyright        2019 www.dsc-gmbh.com / All rights reserved.
 */

(function() {
	"use strict";
	clickjacking();
	prototyping();
})();

function clickjacking() {

	"use strict";

	function unlock() {
		var antiClickjack = document.getElementById("antiClickjack");
		antiClickjack.parentNode.removeChild(antiClickjack);
	}

	if(top === self) { // page is not embedded
		unlock();
	}
	else { // page embedded, check for same domain
		try { // try to access a property of parent to check same domain
			var loc = parent.location.href;
			unlock();
		}
		catch(e) {
		} // fail gracefully and stay hidden
	}

}

function prototyping() {
	Object.defineProperty(Object.prototype, 'dscHasOwnNestedProperty',{
		value: function(sPropertiePath: string) {
			if(!sPropertiePath)
				return false;

			var aProperties = sPropertiePath.split('.');
			var oTmpObj = this;

			for(var i = 0; i < aProperties.length; i++) {
				var sTmpProp = aProperties[i];

				if(!oTmpObj || !oTmpObj.hasOwnProperty(sTmpProp)) {
					return false;
				} else {
					oTmpObj = oTmpObj[sTmpProp];
				}
			}
			return true;
		},
		writable: true,
		configurable: true,
		enumerable: false
	});
}

//Implement the CustomEvent function for >IE9
(function () {

	if ( typeof window.CustomEvent === "function" ) return false;

	function CustomEvent ( event, params ) {
		params = params || { bubbles: false, cancelable: false, detail: null };
		var evt = document.createEvent( 'CustomEvent' );
		evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
		return evt;
	}

	window.CustomEvent = CustomEvent;
})();
